<template>
  <div>

    <CRow>
      <CCol lg="12">
        <CCard>
        <CCardHeader>
          <slot name="header">
            <h4 class="float-left">Pencarian </h4>
            <!-- <a href="/#/login" class="float-right btn btn-success ">Login</a> -->

          </slot>
        </CCardHeader>
        <CCardBody>

        <CRow>    
              <CCol sm="3">
              <CInput
                v-model="nourut_aset"           
                placeholder="Cari Nomor Urut Aset"
              />
              </CCol>          
              <CCol sm="3">
              <CInput
                v-model="nomor_hak"
                placeholder="Cari Nomor Hak"
              />  
              </CCol>

              <CCol sm="3">
              <CInput
                v-model="jenis_hak"
                placeholder="Cari Jenis Hak"
              />  
              </CCol>
              <CCol sm="3">
              <select class="form-control" v-model='namadesa'>
                <option v-for='opt in kelurahans' v-bind:value="opt">{{opt}}</option>
              </select>
              </CCol>               
              <CCol sm='12' class='float-right text-right'>
                <hr>
                <CButton color="secondary" @click="reset()" class='mr-3'>Reset</CButton>
                <CButton color="primary" @click="cariData()">Cari Aset</CButton>
              </CCol>
        </CRow>      


            <!-- <CAlert v-if="dataslength==0" show color="danger" class='text-center'>Kosong! Silahkan ketik kolom pencarian.</CAlert> -->

            <div v-if="dataslength>0">
              <div v-for="rs in pencarians" :key="rs.id_arsip" class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title"><CBadge color="success">{{rs.tipe_hak}}</CBadge>  - <span class=""><b>{{rs.namadesa}}</b></span>
                    <span class="float-right">
                      <CButton variant="outline" size="sm"  @click="pinjamArsip(rs.id_arsip)"
                v-c-tooltip.hover.click="'Pinjam Arsip!'" class='mr-2' color="dark"><CIcon name="cil-transfer"/></CButton>
                      <CButton variant="outline" size="sm" 
                v-c-tooltip.hover.click="'Print Arsip!'" @click="openLink(rs.id_arsip)" class='mr-2' color="dark"><CIcon name="cil-print"/></CButton>
                      <CButton variant="outline"  
                v-c-tooltip.hover.click="'Download Arsip!'" @click="downloadData(rs.id_arsip)" size="sm" color="dark"><CIcon name="cil-copy"/></CButton>
                    </span>
                  </h5>
                  <hr>
                  <div class="row">
                    <div class="col-6"><b>NO HAK : {{rs.nomor_hak}}</b> <br>{{rs.status}}</div>
                    <div class="col-6 text-right" ><small><b>SU : {{rs.su}}</b><br>NIB : {{rs.nib}}</small></div>
                  </div>
                </div>
              </div>
            </div>


            <CSpinner v-show="mySpinner" color="success" style="position:fixed;left: 50%;top:50%"/>

            <CModal
              :show.sync="filenull"
              :no-close-on-backdrop="true"
              :centered="true"
              title="Warning"
              color="danger"
            >
              File tidak tersedia / belum diupload.
              <template #header>
                <h6 class="modal-title">Custom smaller modal title</h6>
                <CButtonClose @click="filenull = false" class="text-white"/>
              </template>
              <template #footer>
                <CButton @click="filenull = false" color="success">Ok</CButton>
              </template>
            </CModal>

        </CCardBody>
      </CCard>

        <CCard>
        <CCardHeader>
          <slot name="header">
            <!-- <CIcon name="cil-grid"/> --><h4 class="float-left">Data Aset Per Instansi</h4> <select style="margin-left:15px;width:100px" @change="loadData" v-model="tahun" class="form-control-sm float-left form-control"><option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option></select>
            <!-- <a href="/#/keseluruhan/add" class="float-right btn btn-success form-control-sm">Tambah Aset Baru</a> -->

          </slot>
        </CCardHeader>
        <CCardBody>

        <table class="table table-bordered  table-striped" id="datatablex">
          <thead class="bg-dark">
            <tr>
              <th style="width:30px">#</th>
              <th>NIB </th>
              <th>Tipe Hak </th>
              <th>No Hak </th>
              <th>SU </th>
              <th>Kelurahan </th>
              <th>Lokasi</th>
              <th>Luas</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tfoot v-if="dataslength==0">
            <tr >
              <td align="center" colspan="9">DATA KOSONG</td>
            </tr>
          </tfoot>
          <tbody >
            <template v-for="rs in datas"   v-if="dataslength!=0">
            <tr :key="rs.properties.TARGET_F_1"  >
              <td>{{rs.properties.TARGET_F_1}}</td>
              <td>{{rs.properties.NIB}}</td>
              <td>{{rs.properties.TIPEHAK}}</td>
              <td>{{rs.properties.HAK}}</td>
              <td>{{rs.properties.SU}}</td>
              <td>{{rs.properties.DESA}}</td>
              <td>{{rs.properties.Letak____1}}</td>
              <td>{{rs.properties.LUASTERTUL}}</td>
              <td><CButton color="secondary" @click="toggle(rs.properties.TARGET_F_1, JSON.stringify(rs.geometry.coordinates),JSON.stringify(rs.properties))" class='form-input-sm input-sm'>Peta</CButton></td>
            </tr>
            <!-- <tr v-if="opened.includes(rs.properties.TARGET_F_1)">
              <td colspan="9" v-bind:id="'map_'+rs.properties.TARGET_F_1">
                  <l-map style="height: 300px;width: 100%;" :zoom="zoom" :center="center">
                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                    <l-polygon :lat-lngs="coordinates"></l-polygon>
                  </l-map>
              </td>
            </tr> -->
          </template>
          </tbody>
        </table>  

    </CCardBody>
  </CCard>  
  
  </CCol>
  </CRow>
  </div>
</template>

<style scoped>
.hiddenRow{
  padding: 0 !important;
}
</style>
 
<script>
import 'jquery/dist/jquery.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import axios from 'axios';
import $ from 'jquery'; 
import { LMap, LTileLayer, LPolygon, LMarker } from 'vue2-leaflet';


export default {
  
  filters: {
    rupiah: function(value) {
      // return value.toUpperCase();
      // Create our number formatter.
      var formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        
      });

      return formatter.format(value); /* $2,500.00 */
    }
  },
  computed : {
    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => new Date().getFullYear() - index)
    }
  },
  mounted(){
    this.loadData();
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolygon
  },
  data: function() {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
              '&copy; <a target="_blank" href="http://osm.org/copyright">BPN Kantah Kota Pontianak</a>',
            zoom: 13,
            center: [-0.0353948,109.2615099],
            gridColumns: ['No', 'Nomor Berkas', 'Tahun','Jenis Hak', 'Nomor Hak','Kegiatan','Atas Nama', 'Rak','Tgl'],
            visible : false,
            headers: [
              {
                text: 'No',
                align: 'start',
                sortable: false,
                value: 'no',
              },
              { text: 'Nomor Berkas', value: 'nomor_berkas' },
              { text: 'Tahun', value: 'tahun_arsip' },
              { text: 'Jenis Hak', value: 'jenis_hak' },
              { text: 'Nomor Hak', value: 'nomor_hak' },
              { text: 'Kegiatan', value: 'kegiatan' },
              { text: 'Atas Nama', value: 'atas_nama' },
              { text: 'Rak', value: 'rak' },
              { text: 'Tgl - Tgl Kembali', value: 'tgl' },
            ],
            coordinates:[],
            dataslength : 0,
            nomor_hak:"",
            opened: [],
            su:"",
            nama_peminjam:"",
            nib:"",
            jenis_hak:"",
            nourut_aset:"",
            mySpinner : false,
            filenull : false,
            namadesa:"PILIH KEL/DESA",
            kelurahans:['PILIH KEL/DESA','AKCAYA','BANGKA BELITUNG','PARIT TOKAYA','BATU LAYANG','SIANTAN HULU'],
            products:[],
            options:['Pinjam','Print','Copy'],
            items:[],
            datas:[],
            datasbackup:[],
            datascari:[],
            toasts: [],
            pencarians:[],
            itemlengths:0,
            tahun: new Date().getFullYear(),
        }
  },
  methods: {
    toggle(id, koor, prop) {
      // const index = this.opened.indexOf(id);
      // if (index > -1) {
      //   this.opened.splice(index, 1)
      // } else {
      //   this.coordinates = JSON.parse(koor);
      //   this.opened.push(id);
      //   // document.getElementById('map_'+koor)
      // }
      this.$store.commit('setProp', prop);
      this.$store.commit('setCoord', koor);
      this.$router.push("/instansi/detail/"+id);
    },
    cariData(){
      this.mySpinner = true;
        var data;
      if(this.nourut_aset){
        if(this.datascari.length>0){
          data = this.datascari;
        }
        else{
          data = this.datas;
        }
        this.datascari = data.filter(d => d.properties.TARGET_F_1 === this.nourut_aset );
        data.forEach(row => {   
          if(row.properties.TARGET_F_1 ==this.nourut_aset){
            this.datascari.push(row); 
          }
        });
        this.dataslength = this.datascari.length;
        this.datas = this.datascari;

      }
      if(this.nomor_hak){
          if(this.datascari.length>0){
            data = this.datascari;
          }
          else{
            data = this.datas;
          }
          data.forEach(row => {   
            if(row.properties.HAK == this.nomor_hak){
              this.datascari.push(row); 
            }
          });
      }

      if(this.jenis_hak){
          if(this.datascari.length>0){
            data = this.datascari;
          }
          else{
            data = this.datas;
          }
          data.forEach(row => {   
            if(row.properties.TIPEHAK == this.jenis_hak){
              this.datascari.push(row); 
            }
          });
      }

      if(this.namadesa!='PILIH KEL/DESA'){
          if(this.datascari.length>0){
            data = this.datascari;
          }
          else{
            data = this.datas;
          }
          data.forEach(row => {   
            if(row.properties.DESA == this.namadesa){
              this.datascari.push(row); 
            }
          });
      }
          // alert(JSON.stringify(this.datascari));

      this.data = this.datascari;

      this.mySpinner = false;
    },
    loadData: function() {

      this.mySpinner = true;
      const cari = { 
              namadesa: this.namadesa, 
              nomor_hak: this.nomor_hak,
              su: this.su,
              nib: this.nib,
              };
      axios.get("https://sigagah.s3.ap-southeast-1.amazonaws.com/data14.geojson")
        .then((response) => {

            
             this.datas = response.data.features;
             this.datasbackup = response.data.features;
             // alert(JSON.stringify(this.datas));
             this.dataslength = response.data.length;
            this.mySpinner = false;
        })      

      if (this.tahun == null){
          $('#datatable').DataTable({
            "processing": true,
              "serverSide": true,
               "pageLength": 50,
               responsive: true,
              "ajax":"https://sigagah.s3.ap-southeast-1.amazonaws.com/data14.geojson",
              "dataSrc": "features",
              "columns": [
                  { "data": "properties.TARGET_FID", class:"text-center"  },
                  { "data": "properties.NIB", class:"text-center"  },
                  { "data": "properties.TIPEHAK" , class:"text-center" },
                  { "data": "properties.SU", class:"text-center" },
                  { "data": "properties.HAK", class:"text-center" },
                  { "data": "properties.DESA", class:"text-center" },
                  { "data": "properties.LUASTERTUL", class:"text-center" },
                  { "data": null,
                    render: function(data, type, row, meta)
                      {
                          return "<a href=''>Edit</a>";
                      },
                  },
              ]  

          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      else{
          $('#datatable').DataTable({
             "ajax":{
                 "url": "https://sigagah.s3.ap-southeast-1.amazonaws.com/data14.geojson",
                  "dataSrc": "features"
              },
              "columns": [
                  { "data": "properties.TARGET_F_1", class:"text-center"  },
                  { "data": "properties.NIB", class:"text-center"  },
                  { "data": "properties.TIPEHAK" , class:"text-center" },
                  { "data": "properties.HAK", class:"text-center" },
                  { "data": "properties.SU", class:"text-center" },
                  { "data": "properties.DESA", class:"text-center" },
                  { "data": "properties.DESA", class:"text-center" },
                  { "data": "properties.LUASTERTUL", class:"text-center" },
                  { "data": null,
                    render: function(data, type, row, meta)
                      {
                          return "<a href=''>Edit</a>";
                      },
                  },
              ]        
 
          });
          
          $.fn.dataTableExt.sErrMode = 'throw';
          
        // })
      }
      
    }
  }
}
</script>